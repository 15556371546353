import React from 'react';
import { initializeApp } from "firebase/app";
import { get, getDatabase, onValue, ref } from "firebase/database";
import { apiUrl, firebaseConfig } from "../utils/const";
import { Line, Legend, Bar, BarChart, AreaChart, Area, LineChart, CartesianGrid, Label, ReferenceLine, Tooltip, XAxis, YAxis } from 'recharts';
import { useParams } from 'react-router-dom';
import { Refresh } from '@material-ui/icons'
import toast from 'react-hot-toast';
import { Modal } from 'react-bootstrap';



const app = initializeApp(firebaseConfig);
const db = getDatabase(app, firebaseConfig.databaseUrl);

interface DatasetInfo {
    name: string;
    uuid: string;
    data: Array<{
        date: string;
        time: number;
        value: number;
    }>;
}

interface ChartInfo {
    name: string;
    uuid: string;
    display_type: string;
    charts: Array<{id: string; name: string}>;
    chart_type: 'equation' | 'overlap';
    favorite_name: string;
    data: Array<{
        date: string;
        time: number;
        value: number;
        dates: {[key: string]: number};
    }>;
    x_lines: Array<{name?: string, value?: number}>;
}

interface IProps {
    favorite?: boolean;
    favorite_name?: string;
}

interface IState {
    datasets?: {[key: string]: DatasetInfo};
    charts?: {[key: string]: ChartInfo};
    favorite_selected: string;
    favorite?: boolean;
    favorite_name?: string;
    favorites: Array<string>;

    data_deals_info?: {
        deals: Array<any>;
        label: string;
        chart_name: string;
    };
}

class NewDashboardComponent extends React.Component<IProps, IState> {

    current_dashboard_id?: number;

    constructor(props: IProps) {
        super(props);

        this.state = {
            favorite_selected: this.props.favorite_name || '',
            favorite: this.props.favorite,
            favorite_name: this.props.favorite_name,
            favorites: [],
        }
    }

    updateCharts() {
        toast.promise(fetch(apiUrl + "update_charts"), {
          loading: "Loading...",
          success: "Charts updated!",
          error: "Error!",
        });
      }

    componentDidMount(): void {

        onValue(ref(db, 'datasets'), (snapshot: any) => {
            const datasets = snapshot.val();
            this.setState({ datasets });
        });

        onValue(ref(db, 'charts_datasets'), (snapshot: any) => {
            const charts = snapshot.val();
            const favorites = Array.from(new Set(Object.values(charts).filter(value => !!(value as ChartInfo).favorite_name).map(value => (value as ChartInfo).favorite_name)) as Set<string>);
            console.log({charts, favorites });
            this.setState({ charts, favorites });
        });

        onValue(ref(db, 'update_dashboard_pages'), snap => {
            const value = snap.val() as number;

            if(!this.current_dashboard_id) this.current_dashboard_id = value;
            else if(this.current_dashboard_id != value) {
                window.location.reload();
                toast.success("Dashboard pages requested!");
            } 
        });

        if(this.auto_scroll_down_timeout) clearTimeout(this.auto_scroll_down_timeout);
        // this.autoScrollDown();
    }

    onClickHandler(chart_uid: string) {
        return (event: any) => {
            console.log(chart_uid, "=>", event);
            this.showDealsModal(event.activePayload, event.activeLabel, chart_uid);
        }
    }

    showDealsModal(deals: Array<string>, label: string, chart_name: string) {
        console.log({chart_name, label, deals});
        this.setState({data_deals_info: {chart_name, label, deals}})
    }

    chartOptionsBuilder(chart: ChartInfo) {

        const datas = [];

        const colors = ['#00FF00', '#8884D8', '#5D5D81', '#BFCDE0']

        
        if(chart.chart_type == 'equation') {
            if(chart.display_type == 'Line') datas.push(<Line dataKey='value'></Line>)

            if(chart.display_type == 'Bar') datas.push(<Bar dataKey='value'></Bar>)

            if(chart.display_type == 'Area') datas.push(<Area dataKey='value'></Area>)
        }

        if(chart.chart_type == 'overlap') {
            if(chart.display_type == 'Line') {
                chart.charts.forEach((chart, index) => {
                    datas.push(<Line key={`${chart.id}-${index}`} stroke={colors[index]} fill={colors[index]} name={chart.name} dataKey={`dates.${chart.name}`}></Line>)
                })
            }

            if(chart.display_type == 'Bar') {
                chart.charts.forEach((chart, index) => {
                    datas.push(<Bar key={`${chart.id}-${index}`} stroke={colors[index]} fill={colors[index]} name={chart.name} dataKey={`dates.${chart.name}`}></Bar>)
                })
            }

            if(chart.display_type == 'Area') {
                chart.charts.forEach((chart, index) => {
                    datas.push(<Area key={`${chart.id}-${index}`} stroke={colors[index]} fill={colors[index]} name={chart.name} dataKey={`dates.${chart.name}`}></Area>)
                })
            }
        }

        const id = chart.data[0].date + '-' + chart.data[chart.data.length - 1].date;

        const chart_params = {
            width: 600,
            height: 400,
            key: chart.uuid,
            data: chart.data,
            syncId: id,
            onClick: this.onClickHandler(chart.name),
        }

        const charts_child = [
            <Legend verticalAlign="top" height={36}/>,
            <Tooltip/>,
            <XAxis dataKey="date" />,
            <YAxis />,
        ]

        if((chart.x_lines || []).length != 0) {
            chart.x_lines.forEach(x_line => {
                charts_child.unshift(<ReferenceLine strokeDasharray="3 3" stroke='black' key={`${x_line.value}`} y={x_line.value} label={x_line.name} />)
            })
        }

        if(chart.display_type == 'Line') return (
            <LineChart {...chart_params}>
                {charts_child}
                {datas}
            </LineChart>
        )

        if(chart.display_type == 'Bar') return (
            <BarChart {...chart_params}>
                {charts_child}
                {datas}
            </BarChart>
        )

        if(chart.display_type == 'Area') return (
            <AreaChart {...chart_params}>
                {charts_child}
                {datas}
            </AreaChart>
        )
    }

    auto_scroll_down_timeout?: NodeJS.Timeout;
    auto_scroll_moltiplication = 1;
    autoScrollDown() {

        const root_element = document.getElementById('root')!;
        root_element.scrollBy(0, 5);
        
        if(root_element.offsetHeight + root_element.scrollTop >= root_element.scrollHeight) root_element.scrollTo(0, 0);

        if(this.auto_scroll_down_timeout) clearTimeout(this.auto_scroll_down_timeout);
        this.auto_scroll_down_timeout = setTimeout(() => this.autoScrollDown(), 1000);
    }

    render(): React.ReactNode {
        return (
            <>
            <Modal show={this.state.data_deals_info != undefined} centered onHide={() => this.setState({ data_deals_info: undefined })}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.state.data_deals_info ? this.state.data_deals_info!.chart_name : ''}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <h2>{this.state.data_deals_info ? this.state.data_deals_info.label : ''}</h2>
                    <hr />
                    {Object.entries(this.state.data_deals_info ? this.state.data_deals_info.deals[0].payload.deals || {} : {}).map(([chart_name, info]) =>
                            <>
                                <h3>{chart_name || ''}</h3>
                                <ol style={{maxHeight: '10em', overflowY: 'auto'}}>
                                    {((info as Array<string>) || []).map((deal: string) => <li>
                                        <a href={`https://futura2.pipedrive.com/deal/${deal}`} target="_blank">{deal}</a>
                                    </li>)}
                                </ol>  
                                <hr />
                            </>
                    )}
                </Modal.Body>

                <Modal.Footer>
                </Modal.Footer>
            </Modal>
                <div className='w-100 h-100'>
                    <div className='w-100 row my-4'>
                        <div className='col-4'></div>
                        <div className='col-4 text-center'><h1 className='m-0'>{this.state.favorite_name || 'Charts'}</h1></div>
                        <div className='col-4'>
                            <div className='d-flex justify-content-end align-items-center'>
                                <select className='form-select m-0' onChange={(e) => {
                                    const value = e.target.value;
                                    this.setState({
                                        favorite_selected: value, 
                                        favorite_name: value, 
                                        favorite: value != '',
                                    });
                                }} value={this.state.favorite_selected} style={{width: '10em'}}>
                                    <option value="">Charts</option>
                                    {this.state.favorites.map(favorite => <option key={favorite} value={favorite}>{favorite}</option>)}
                                </select>
                                <button className='btn btn-primary ms-1' onClick={() => this.updateCharts()}><Refresh></Refresh></button>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        {this.state.charts && 
                            Object.values(this.state.charts)
                                .filter(chart => (chart.data || []).length != 0)
                                .filter(chart => !this.state.favorite || (chart.favorite_name || '').toLowerCase() == (this.state.favorite_name || '').toLowerCase())
                                .map(chart => 
                                    <div className='col-xl-4 col-12' key={chart.uuid}>
                                        <div className='w-100 text-center'>
                                            <h2 className='fw-bold'>{chart.name}</h2>
                                        </div>
                                        <div className='w-100 d-flex justify-content-center'>
                                            {this.chartOptionsBuilder(chart)}
                                        </div>
                                    </div>    
                        )}
                    </div>
                </div>
            </>
        );
    }

}

function NewDashboard(props: IProps) {
    
    const { name } = useParams();

    const new_props = {...props};

    new_props.favorite_name = name || '';

    return <NewDashboardComponent {...new_props}></NewDashboardComponent>
}

export default NewDashboard;