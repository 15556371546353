import "./Edit.css";
import * as auth from "firebase/auth";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { apiUrl, firebaseConfig } from "../../utils/const";
import { initializeApp } from "firebase/app";
import { browserLocalPersistence, getAuth, onAuthStateChanged, setPersistence, User } from "firebase/auth";
import React from "react";
import { getDatabase, ref, onValue, set } from "firebase/database";
import SelectRealtimeDb from "../../shared/components/SelectRealtimeDb";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { ArrowForwardIos, ArrowBackIos } from "@material-ui/icons";
import { toast } from "react-hot-toast";
import { Favorite } from "@material-ui/icons";
import { Modal } from "react-bootstrap";
import { Subject } from "rxjs";

const app = initializeApp(firebaseConfig);
const fAuth = getAuth();
const futuraFunctions = getFunctions();
const db = getDatabase(app, firebaseConfig.databaseUrl);

// function getUser(): Promise<User> {
//   return new Promise<User>((resolve, reject) => {
//     httpsCallable(futuraFunctions, 'getUser')()
//       .then(result => {
//         resolve(result.data as User);
//       })
//       .catch(err => {
//         reject(err);
//       })
//   });
// }

interface IState {
  user?: User;
  start_date: string;
  end_date: string;
  stages: Array<{ name: string; id: string; estimated_values: { [key: string]: number }; favorite: boolean }>;
  show_favorite_dialog: boolean;
  favorite_dialog_options: { name: string; chart: number; type: string, uuid: string };
  favorites: Array<{ name: string; chart: number; type: string; uuid: string }>;
  favorite_selected: string;
}

class Edit extends React.Component<{}, IState> {

  onFavoriteChange = new Subject<string>();

  constructor(props: {}) {
    super(props);

    this.state = {
      user: undefined,
      start_date: new Date().toISOString().split("T")[0],
      end_date: new Date().toISOString().split("T")[0],
      stages: [],
      show_favorite_dialog: false,
      favorite_dialog_options: {
        name: "",
        chart: -1,
        type: "",
        uuid: "",
      },
      favorites: [],
      favorite_selected: "",
    };
  }

  googleLogin() {
    const auth_object = auth.getAuth();
    const provider = new auth.GoogleAuthProvider().addScope(
      "https://www.googleapis.com/auth/user.birthday.read, https://www.googleapis.com/auth/calendar.events.readonly, https://www.googleapis.com/auth/admin.directory.customer.readonly"
    );
    setPersistence(auth_object, browserLocalPersistence).then(() => {
      auth.signInWithPopup(auth_object, provider);
    });
  }

  componentDidMount() {
    // getUser().then(user => {
    //   console.log(user)
    //   this.setState({user: user});
    // });
    onAuthStateChanged(fAuth, (user) => {
      if (user) {
        this.setState({ user: user });
      } else {
        this.setState({ user: undefined });
      }
    });

    onValue(ref(db, "stages"), (v) => {
      this.setState({ stages: v.val() });
    });

    onValue(ref(db, "settings/date"), (snapshot) => {
      this.setState({ start_date: snapshot.val().start });
      this.setState({ end_date: snapshot.val().end });
    });

    onValue(ref(db, "favorites"), (snapshot) => {
      const favorites = Object.entries(snapshot.val()).map((v) => v[1]) as any;
      // console.log(favorites);
      this.setState({ favorites });
    });
  }

  logout() {
    auth.signOut(fAuth);
  }

  setDate(type: "start" | "end", date: string) {
    set(ref(db, "settings/date/" + type), date);
  }

  showFavoriteDialog() {
    this.setState({ show_favorite_dialog: true });
  }

  async save(recursion = false) {
    // toast.promise(fetch("http://localhost:4501/update_dashboard", { method: "GET" }))
    //   .then(res => {
    //     if(res.status == 404) alert('No data found');
    //     // res.json().then(console.log);
    //   })
    //   .catch(err => {
    //     alert('No data found');
    //     console.log(err)
    //   }, );
    if(!recursion && this.state.favorite_selected != '') return await this.saveFavorite(false);
    return toast
      .promise(fetch(apiUrl + "update_dashboard", { method: "GET", headers: {'Custom-Options': this.state.favorite_selected} }), {
        loading: "Loading...",
        success: "Dati aggiornati con successo",
        error: "Si è verificato un errore!",
      })
      .then((res) => {
        if (res.status == 404) alert("No data found");
      });
  }

  saveFavorite(is_new = true) {
    this.setState({ show_favorite_dialog: false });
    if(is_new) this.setFavoriteOptions({uuid: ''});
    if(this.state.favorite_selected != '') this.setFavoriteOptions({uuid: this.state.favorite_selected});
    toast.promise(
      new Promise<void>(async (resolve, reject) => {
        try {
          await this.save(true);
          await fetch(apiUrl + "save_to_favorite", { method: "POST", body: JSON.stringify(this.state.favorite_dialog_options) });
        } catch {
          reject();
        }
        resolve();
      }),
      {
        loading: "Loading...",
        success: "Dati salvati con successo",
        error: "Si è verificato un errore!",
      }
    );
  }

  setFavoriteOptions(options: { name?: string; chart?: number; type?: string, uuid?: string }) {
    const to_save = {
      name: options.name ?? this.state.favorite_dialog_options.name,
      chart: options.chart ?? this.state.favorite_dialog_options.chart,
      type: options.type ?? this.state.favorite_dialog_options.type,
      uuid: options.uuid ?? this.state.favorite_dialog_options.uuid ?? "",
    };

    this.setState({ favorite_dialog_options: to_save });
  }

  changePreset(value: string) {
    this.setState({ favorite_selected: value });
    const preset = this.state.favorites.find((v) => v.uuid == value);

    if (preset) this.setFavoriteOptions({ name: preset.name, chart: preset.chart, type: preset.type });
    else this.setFavoriteOptions({ name: "", chart: -1, type: "" });

    this.onFavoriteChange.next(value);
  }

  swiperObject: any = null;

  render() {
    const selectionRange = {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    };
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <Modal show={this.state.show_favorite_dialog} centered onHide={() => this.setState({ show_favorite_dialog: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Save to favorite</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="form-group">
              <label className="mb-1 text-muted">Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Insert a name"
                value={this.state.favorite_dialog_options.name}
                onChange={(e) => this.setFavoriteOptions({ name: e.target.value })}
              />
            </div>
            <div className="form-group mt-2">
              <label className="mb-1 text-muted">Chart</label>
              <select className="form-select" value={this.state.favorite_dialog_options.chart} onChange={(e) => this.setFavoriteOptions({ chart: +e.target.value })}>
                <option value="-1" hidden>
                  Select a chart
                </option>
                {this.state.stages
                  .sort((a, b) => +a.id - +b.id)
                  .map((stage) => (
                    <option key={stage.id} value={stage.id}>
                      {stage.name} ({stage.id})
                    </option>
                  ))}
              </select>
            </div>
            <div className="form-group mt-2">
              <label className="mb-1 text-muted">Type</label>
              <select className="form-select" value={this.state.favorite_dialog_options.type} onChange={(e) => this.setFavoriteOptions({ type: e.target.value })}>
                <option value="" hidden>
                  Select a type
                </option>
                <option value="average">avg time</option>
                <option value="count">quantity</option>
                <option value="working_days_count">working_days_count</option>
                <option value="lead_eod">lead_eod</option>
              </select>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button className="btn btn-primary" onClick={() => this.saveFavorite()}>
              Save
            </button>
            <button className="btn btn-outline-secondary">Close</button>
          </Modal.Footer>
        </Modal>
        {this.state.user ? (
          <div className="w-100 h-100">
            <div className="w-100 bg-white px-3 py-1 d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-start align-items-center">
                <img src={this.state.user.photoURL || ""} width="50" height="50" className="rounded-circle me-2" />
                <h2 className="text-primary m-0">{this.state.user.displayName}</h2>
              </div>
              <button className="btn btn-outline-danger" onClick={() => this.logout()}>
                Logout
              </button>
            </div>
            <Swiper
              slidesPerView={1}
              allowTouchMove={false}
              keyboard={true}
              onSwiper={(s) => {
                this.swiperObject = s;
              }}
            >
              <SwiperSlide>
                <div className="d-flex flex-column align-items-center w-100 h-100">
                  <div className="h-100 w-100 mt-2 px-3">
                    <div className="w-100">
                      <div className="w-100 text-center">
                        <h2 className="text-primary">Presets</h2>
                      </div>
                      <select className="form-select" onChange={(e) => this.changePreset(e.target.value)} value={this.state.favorite_selected}>
                        <option value="">Live</option>
                        <optgroup label="Presets">
                          {this.state.favorites.map((favorite) => (
                            <option key={favorite.uuid} value={favorite.uuid}>
                              {favorite.name}
                            </option>
                          ))}
                        </optgroup>
                      </select>
                      {this.state.favorite_selected != '' && <div className="w-100">
                        <div className="form-group">
                          <label className="mb-1 text-white fw-bold mt-2">Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Insert a name"
                            value={this.state.favorite_dialog_options.name}
                            onChange={(e) => this.setFavoriteOptions({ name: e.target.value })}
                          />
                        </div>
                        <div className="form-group mt-2">
                          <label className="mb-1 text-white fw-bold">Chart</label>
                          <select className="form-select" value={this.state.favorite_dialog_options.chart} onChange={(e) => this.setFavoriteOptions({ chart: +e.target.value })}>
                            <option value="-1" hidden>
                              Select a chart
                            </option>
                            {this.state.stages
                              .sort((a, b) => +a.id - +b.id)
                              .map((stage) => (
                                <option key={stage.id} value={stage.id}>
                                  {stage.name} ({stage.id})
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group mt-2">
                          <label className="mb-1 text-white fw-bold">Type</label>
                          <select className="form-select" value={this.state.favorite_dialog_options.type} onChange={(e) => this.setFavoriteOptions({ type: e.target.value })}>
                            <option value="" hidden>
                              Select a type
                            </option>
                            <option value="average">avg time</option>
                            <option value="count">quantity</option>
                            <option value="working_days_count">working_days_count</option>
                            <option value="lead_eod">lead_eod</option>
                          </select>
                        </div>
                      </div>}
                    </div>
                    <div className="w-100 text-center mt-2 mb-1">
                      <h2 className="text-primary">Options</h2>
                    </div>
                    <div className="w-100 mt-2">
                      <h2 className="text-white">Pipeline</h2>
                      <SelectRealtimeDb favoriteChange={this.onFavoriteChange} favorite_selected={this.state.favorite_selected} value_name="deal_vertical" display_name="deal_vertical" realtime_path="settings/verticals"></SelectRealtimeDb>
                    </div>
                    <div className="w-100 mt-2">
                      <h2 className="text-white">Channel</h2>
                      <SelectRealtimeDb favoriteChange={this.onFavoriteChange} favorite_selected={this.state.favorite_selected} value_name="deal_channel" display_name="deal_channel" realtime_path="settings/channels"></SelectRealtimeDb>
                    </div>
                    <div className="w-100 mt-2">
                      <h2 className="text-white">Sales</h2>
                      <SelectRealtimeDb favoriteChange={this.onFavoriteChange} favorite_selected={this.state.favorite_selected} value_name="person_id" display_name="email" realtime_path="settings/owners"></SelectRealtimeDb>
                    </div>
                    <div className="w-100 mt-2">
                      <h2 className="text-white">Kind</h2>
                      <SelectRealtimeDb favoriteChange={this.onFavoriteChange} favorite_selected={this.state.favorite_selected} value_name="deal_kind" display_name="deal_kind" realtime_path="settings/kinds"></SelectRealtimeDb>
                    </div>
                    <div className="w-100 mt-2">
                      <h2 className="text-white">Status</h2>
                      <SelectRealtimeDb favoriteChange={this.onFavoriteChange} favorite_selected={this.state.favorite_selected} value_name="status" display_name="status" realtime_path="settings/status"></SelectRealtimeDb>
                    </div>
                    <div className="w-100 mt-2">
                      <h2 className="text-white">Data</h2>
                      <div className="row">
                        <div className="col-6">
                          <DatePicker
                            className="form-control"
                            selected={new Date(this.state.start_date)}
                            onChange={(date) => this.setDate("start", date!.toISOString())}
                            selectsStart
                            startDate={new Date(this.state.start_date)}
                            endDate={new Date(this.state.end_date)}
                            maxDate={new Date(this.state.end_date)}
                            dateFormat="dd/MM/yyyy"
                          />
                        </div>
                        <div className="col-6">
                          <DatePicker
                            className="form-control"
                            selected={new Date(this.state.end_date)}
                            onChange={(date) => this.setDate("end", date!.toISOString())}
                            selectsEnd
                            startDate={new Date(this.state.start_date)}
                            endDate={new Date(this.state.end_date)}
                            minDate={new Date(this.state.start_date)}
                            maxDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="w-100 mt-2">
                      <h2 className="text-white">Group by</h2>
                      <SelectRealtimeDb favoriteChange={this.onFavoriteChange} favorite_selected={this.state.favorite_selected} realtime_path="settings/group_by"></SelectRealtimeDb>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-100 h-100 overflow-auto">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        {this.state.stages.map((stage) => (
                          <th scope="col" key={stage.id}>
                            {stage.name}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex">avg time</div>
                        </td>
                        {this.state.stages.map((stage, index) => (
                          <td key={index + "" + stage.id}>
                            <input
                              type="number"
                              onChange={(target) => {
                                const value = target.target.value;
                                set(ref(db, `stages/${index}/estimated_values/avg`), value);
                              }}
                              value={stage.estimated_values["avg"]}
                            />
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <th scope="row">quantity</th>
                        {this.state.stages.map((stage, index) => (
                          <td key={index + "" + stage.id}>
                            <input
                              type="number"
                              onChange={(target) => {
                                const value = target.target.value;
                                set(ref(db, `stages/${index}/estimated_values/quantity`), value);
                              }}
                              value={stage.estimated_values["quantity"]}
                            />
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <th scope="row">working_days_count</th>
                        {this.state.stages.map((stage, index) => (
                          <td key={index + "" + stage.id}>
                            <input
                              type="number"
                              onChange={(target) => {
                                const value = target.target.value;
                                set(ref(db, `stages/${index}/estimated_values/working_days_count`), value);
                              }}
                              value={stage.estimated_values["working_days_count"]}
                            />
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <th scope="row">lead_eod</th>
                        {this.state.stages.map((stage, index) => (
                          <td key={index + "" + stage.id}>
                            <input
                              type="number"
                              onChange={(target) => {
                                const value = target.target.value;
                                set(ref(db, `stages/${index}/estimated_values/lead_eod`), value);
                              }}
                              value={stage.estimated_values["lead_eod"]}
                            />
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </SwiperSlide>
            </Swiper>
            <div className="w-100 mt-5 px-3 d-flex flex-column">
              <div className="row">
                <div className="col-10">
                  <button className="btn btn-primary w-100" onClick={() => this.save()}>
                    Save
                  </button>
                </div>
                <div className="col-2">
                  <button onClick={() => this.showFavoriteDialog()} className="btn btn-secondary w-100">
                    <Favorite></Favorite>
                  </button>
                </div>
              </div>
              <div className="d-flex justify-content-around mt-3">
                <span
                  className="text-white"
                  onClick={() => {
                    if (this.swiperObject) this.swiperObject.slidePrev();
                  }}
                >
                  <ArrowBackIos></ArrowBackIos>
                </span>
                <span
                  className="text-white"
                  onClick={() => {
                    if (this.swiperObject) this.swiperObject.slideNext();
                  }}
                >
                  <ArrowForwardIos></ArrowForwardIos>
                </span>
              </div>
            </div>
          </div>
        ) : (
          <button className="btn btn-primary" onClick={() => this.googleLogin()}>
            Entra con Google
          </button>
        )}
      </div>
    );
  }
}

export default Edit;
