import React from 'react';
import { getDatabase, ref, onValue, set } from 'firebase/database';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '../../utils/const';
import { Subject, Subscription } from 'rxjs';
import { Unsubscribe } from 'firebase/auth';

const app = initializeApp(firebaseConfig);
const db = getDatabase(app, firebaseConfig.databaseUrl);

interface IProps {
    realtime_path: string;
    value_name?: string;
    display_name?: string;
    favorite_selected: string;
    favoriteChange: Subject<string>;
}

interface IState {
    list: Array<{[key: string]: string} | string>;
    selected: string;
    path: string;
}

class SelectRealtimeDb extends React.Component<IProps, IState> {

    optionValue$?: Unsubscribe;
    favoriteChange$?: Subscription;

    constructor(props: IProps) {
        super(props);

        let path;
        if(this.props.favorite_selected != "") path = `favorites/${this.props.favorite_selected}/${this.props.realtime_path}`;
        else path = this.props.realtime_path;
        this.state = {
            list: [],
            selected: "",
            path: path
        }
    }

    componentDidMount() {
        

        console.log('Here');
        this.updatePath();

        this.favoriteChange$ = this.props.favoriteChange.subscribe((v) => {
            let path;
            if(v != "") path = `favorites/${v}/${this.props.realtime_path}`;
            else path = this.props.realtime_path;
            this.favoriteChange$?.unsubscribe();
            this.setState({path});

            this.updatePath(path);
        });
    }

    updatePath(path?: string) {
        if(!path) path = this.props.realtime_path;

        if(this.optionValue$) this.optionValue$();

        this.optionValue$ = onValue(ref(db, path), (snapshot) => {
            this.setState({list: snapshot.val().list});
            this.setState({selected: snapshot.val().selected});
            // console.log(snapshot.val());
        });
    }


    changeSelect(target: EventTarget) {
        let path;
        if(this.props.favorite_selected != "") path = `favorites/${this.props.favorite_selected}/${this.props.realtime_path}`;
        else path = this.props.realtime_path;
        const value = (target as HTMLSelectElement).value;
        // console.log(value);
        set(ref(db, path + "/selected"), value);
    }

    render() {
        return (
            <select className='form-select' onChange={(event) => this.changeSelect(event.target)} value={this.state.selected}>
                <option value="">All</option>

                {this.props.value_name && this.props.display_name 
                ? this.state.list.map(option => <option key={Math.random()} value={(option as any)[this.props.value_name!]}>{(option as any)[this.props.display_name!]}</option>) 
                : this.state.list.map(option => <option key={Math.random()} value={option as string}>{option as string}</option>)}
            </select>
        )
    }
}

export default SelectRealtimeDb;