import React from 'react';
import ReactDOM from 'react-dom/client';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Edit from './routes/edit/Edit';
import Dashboard from './routes/dashboard/Dashboard';
import { firebaseConfig } from './utils/const';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; 
import { Toaster } from 'react-hot-toast';
import Favorite from './routes/dashboard/Favorite';
import CreateData from './routes/CreateData';
import NewDashboard from './routes/NewDashboard';
import {Helmet} from "react-helmet";


// Initialize Firebase
const app = initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <React.StrictMode>
     <Toaster position='bottom-right'/>
     <Helmet>
        <title>Futura Dashboard</title>

        <link rel="apple-touch-icon-precomposed" sizes="57x57" href="assets/favicon/apple-touch-icon-57x57.png" />
        <link rel="apple-touch-icon-precomposed" sizes="114x114" href="assets/favicon/apple-touch-icon-114x114.png" />
        <link rel="apple-touch-icon-precomposed" sizes="72x72" href="assets/favicon/apple-touch-icon-72x72.png" />
        <link rel="apple-touch-icon-precomposed" sizes="144x144" href="assets/favicon/apple-touch-icon-144x144.png" />
        <link rel="apple-touch-icon-precomposed" sizes="60x60" href="assets/favicon/apple-touch-icon-60x60.png" />
        <link rel="apple-touch-icon-precomposed" sizes="120x120" href="assets/favicon/apple-touch-icon-120x120.png" />
        <link rel="apple-touch-icon-precomposed" sizes="76x76" href="assets/favicon/apple-touch-icon-76x76.png" />
        <link rel="apple-touch-icon-precomposed" sizes="152x152" href="assets/favicon/apple-touch-icon-152x152.png" />
        <link rel="icon" type="image/png" href="assets/favicon/favicon-196x196.png" sizes="196x196" />
        <link rel="icon" type="image/png" href="assets/favicon/favicon-96x96.png" sizes="96x96" />
        <link rel="icon" type="image/png" href="assets/favicon/favicon-32x32.png" sizes="32x32" />
        <link rel="icon" type="image/png" href="assets/favicon/favicon-16x16.png" sizes="16x16" />
        <link rel="icon" type="image/png" href="assets/favicon/favicon-128.png" sizes="128x128" />
        <meta name="msapplication-TileImage" content="assets/favicon/mstile-144x144.png" />
        <meta name="msapplication-square70x70logo" content="assets/favicon/mstile-70x70.png" />
        <meta name="msapplication-square150x150logo" content="assets/favicon/mstile-150x150.png" />
        <meta name="msapplication-wide310x150logo" content="assets/favicon/mstile-310x150.png" />
        <meta name="msapplication-square310x310logo" content="assets/favicon/mstile-310x310.png" />

        <meta name="application-name" content="Futura" />
        <meta name="msapplication-TileColor" content="#2C2E3B" />

        <base href="/" />
     </Helmet>
     <BrowserRouter>
      <Routes>
        <Route path="/" element={<CreateData />} />
        <Route path="/dashboard" element={<NewDashboard />} />
        <Route path="/favorite/:name" element={<NewDashboard favorite={true} />} />
        <Route path="edit" element={<Edit />} />
        <Route path="create" element={<CreateData />} />
      </Routes>
     </BrowserRouter>
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
