import { initializeApp } from "firebase/app";
import { browserLocalPersistence, getAuth, onAuthStateChanged, setPersistence } from "firebase/auth";
import { get, getDatabase, onValue, ref, set } from "firebase/database";
import React from "react";
import * as auth from "firebase/auth";
import { apiUrl, firebaseConfig } from "../utils/const";
import { toast } from "react-hot-toast";
import { Close, Delete, Edit, ChevronLeft, ChevronRight } from "@material-ui/icons";
import { Modal, Dropdown, ButtonGroup } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import Multiselect from "multiselect-react-dropdown";
import Autocomplete from 'react-autocomplete';

import 'swiper/css';

const app = initializeApp(firebaseConfig);
const fAuth = getAuth();
const db = getDatabase(app, firebaseConfig.databaseUrl);

interface IProps {}
interface IState {
  user?: auth.User | null;
  selects: { [key: string]: SelectFromDb };
  datasets: Array<{ [key: string]: string }>;
  charts: { [key: string]: ChartCostruction };
  end_date: boolean;
  chart_end_date: boolean;
  selected_dataset: string;
  dialogs_select_dataset: boolean;
  selected_pipeline: number;
  pipelines: Array<{
    id: number;
    name: string;
    stages: Array<{
      id: number;
      name: string;
    }>;
  }>;
  show_equation: boolean;
  chart_creation_selected_datasets: Array<{ name: string; id: string }>;
  chart_creation_variables_container: Array<{ variable: string; dataset_id: string; dataset_name: string }>;
  selected_chart: string;
  dialogs_selected_chart: boolean;
  chart_creation_x_lines: Array<{ name?: string; value?: number }>;
  chart_creation_favorite_selected: string;

  global_start_date?: string;
  global_end_date?: string;
  global_group_by?: string;
  favorite_pages: Array<string>;
}

interface SelectFromDb {
  name: string;
  display: string;
  show_all: boolean;
  db_path: string;
  values: Array<string>;
  selected?: string;
}

interface ChartCostruction {
  name: string;
  search_name_input: string;
  display_type: string;
  chart_type: string;
  equation: string;
  variables: Array<{
    dataset_id: string;
    dataset_name: string;
    variable: string;
  }>;
  charts: Array<{
    name: string;
    id: string;
  }>;
  x_lines: Array<{ name?: string; value?: number }>;
}

const AVAILABLE_SETTINGS_SELECT = ["verticals", "channels", "owners", "kinds", "status", "chart_type"];
const AVAILABLE_CHARTS = ["Line", "Area", "Bar"];

class CreateData extends React.Component<IProps, IState> {

  swiper?: any;

  constructor(props: IProps) {
    super(props);

    this.state = {
      selects: {},
      end_date: true,
      datasets: [],
      charts: {},
      selected_dataset: "",
      dialogs_select_dataset: false,
      pipelines: [],
      selected_pipeline: -1,
      show_equation: false,
      chart_creation_selected_datasets: [],
      chart_creation_variables_container: [],
      selected_chart: "",
      dialogs_selected_chart: false,
      chart_creation_x_lines: [],
      chart_end_date: true,
      favorite_pages: [],
      chart_creation_favorite_selected: '',
    };
  }

  swipeLeft() {
    console.log("swipeLeft");
    if(this.swiper) this.swiper.slidePrev();
  }

  swipeRight() {
    console.log("swipeRight");
    if(this.swiper) this.swiper.slideNext();
  }
  
  googleLogin() {
    const auth_object = auth.getAuth();
    const provider = new auth.GoogleAuthProvider().addScope(
      "https://www.googleapis.com/auth/user.birthday.read, https://www.googleapis.com/auth/calendar.events.readonly, https://www.googleapis.com/auth/admin.directory.customer.readonly"
    );
    setPersistence(auth_object, browserLocalPersistence).then(() => {
      auth.signInWithPopup(auth_object, provider);
    });
  }

  deleteDataset(uuid: string) {
    toast.promise(
      new Promise<void>(async (resolve, reject) => {
        const res = await fetch(apiUrl + "dataset", { method: "DELETE", headers: { Target: uuid } });
        this.selectDatas();
        resolve();
      }),
      {
        loading: "Loading...",
        success: "Data deleted",
        error: "Error!",
      }
    );
  }

  displaySettingName(setting: string): string {
    // Capitalize only the first letter of the setting name and remove underscores.
    return setting.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase());
  }

  componentDidMount() {
    onAuthStateChanged(fAuth, (user) => {
      this.setState({ user });
    });

    for (const setting of AVAILABLE_SETTINGS_SELECT) {
      const path = `settings/${setting}`;
      onValue(ref(db, path), (v) => {
        const selects = this.state.selects;
        // console.log({setting});
        const displayName = this.displaySettingName(setting);
        const value = v.val();
        // console.log({value});
        selects[setting] = {
          display: displayName,
          name: setting,
          show_all: setting != "group_by" && setting != "chart_type",
          db_path: path,
          values: value.list,
          selected: value.selected,
        };
        this.setState({ selects });
        // console.log({selects});
      });
    }

    onValue(ref(db, "datasets"), (snap) => {
      const datasets = snap.val();
      this.setState({ datasets });
    });

    onValue(ref(db, "charts_datasets"), (snap) => {
      const charts = snap.val();
      const favorite_pages = Array.from(new Set(Object.keys(charts || {}).map((key) => charts[key].favorite_name)));
      // console.log({ charts });
      this.setState({ charts, favorite_pages });
    });

    onValue(ref(db, "global_variables"), snap => {
      const global_variables = snap.val();

      this.setState({
        global_start_date: global_variables.start_date || "",
        global_end_date: global_variables.end_date || "",
        global_group_by: global_variables.group_by || "",
      })
    });

    onValue(ref(db, "pipelines"), (snap) => {
      const pipelines = snap.val();
      get(ref(db, "stages")).then((val) => {
        const stages = val.val();
        for (let pipeline of pipelines) {
          pipeline.stages = (stages as Array<any>).filter((stage) => stage.pipeline_id == pipeline.id).sort((a, b) => a.order_nr - b.order_nr);
        }
        this.setState({ pipelines, selected_pipeline: pipelines[0].id });
      });
    });
  }

  getPipeline(id: number) {
    return this.state.pipelines.find((pipeline) => pipeline.id == id);
  }

  selectDatas(uuid?: string) {
    this.setState({ selected_dataset: uuid || "" });
    if (!uuid) {
      (document.querySelector('#data-creation-form input[type="reset"]') as HTMLButtonElement)?.click();
      return;
    }
    toast.promise(
      new Promise<void>(async (resolve, reject) => {
        get(ref(db, `datasets/${uuid}`))
          .then((snap) => {
            const values = snap.val();
            console.log(values);
            this.setState({ end_date: Object.keys(values).includes("end_date") });
            Object.entries(values).forEach(([key, value]) => {
              const element_path = `#data-creation-form *[name="${key}"]`;
              const element = document.querySelector(element_path) as HTMLInputElement;
              if (element) {
                if(element.type == 'select-multiple') Array.from(((element as any) as HTMLSelectElement).options).forEach(o => o.selected = (value as string).split(",").includes(o.value));
                else element.value = value as string;
              }
            });
            resolve();
          })
          .catch(reject);
      }),
      {
        loading: "Loading...",
        success: "Data selected",
        error: "Error!",
      }
    );
  }

  MultiSelect__options() {
    return Object.values(this.state.datasets || {}).sort((a, b) => b.name > a.name ? -1 : 1).map((v) => {
      return { name: v.name, id: v.uuid };
    });
  }

  chartCreationGetVariableName(variable_name: string): string {
    const name_snake_case = variable_name.split(" ").join("_").toLowerCase();
    return `{${name_snake_case}}`;
  }

  MultiSelect__edit_selected(e: Array<{ name: string; id: string }>) {
    this.setState({ chart_creation_selected_datasets: e });

    let variables = this.state.chart_creation_variables_container || [];
    for (const selected of e) {
      if (variables.map((v) => v.dataset_id).includes(selected.id)) continue;
      variables.push({
        dataset_id: selected.id,
        dataset_name: selected.name,
        variable: this.chartCreationGetVariableName(selected.name),
      });
    }
    variables = variables.filter((v) => e.map((s) => s.id).includes(v.dataset_id));
    this.setState({ chart_creation_variables_container: variables });
    console.log(variables);
  }

  async createData(event: React.FormEvent<HTMLFormElement>) {
    // console.log(event);
    const target = event.target as any;
    const to_save: { [key: string]: string } = {};
    for (let i = 0; i < target.length; i++) {
      const element = target[i] as HTMLInputElement;
      // if((["submit", "reset"]).includes(element.type)) continue;
      if (element.name == "") continue;
      // console.log(element);
      console.log(element.type);
      if(element.type == 'select-multiple') to_save[element.name] = Array.from(((element as any) as HTMLSelectElement).options).filter(o => o.selected).map(o => o.value).join(',')
      else to_save[element.name] = element.value;
      // element.value = "";
    }

    if (this.state.selected_dataset != "") to_save["uuid"] = this.state.selected_dataset;
    else delete to_save["uuid"];

    const res = await toast.promise(fetch(apiUrl + "dataset", { method: to_save["uuid"] ? "POST" : "PUT", body: JSON.stringify(to_save) }), {
      loading: "Loading...",
      success: "Data created",
      error: "Error!",
    });
    const response = await res.json();
    this.selectDatas(response.uuid);
    // console.log(to_save);
  }

  addVariable(variable_name: string) {
    const element = document.querySelector("#chart-creation-form textarea") as HTMLTextAreaElement;
    if (element) element.value += this.chartCreationGetVariableName(variable_name);
  }

  _chartCreationClear() {
    this.setState({
      show_equation: false,
      chart_creation_selected_datasets: [],
      chart_creation_variables_container: [],
      chart_creation_x_lines: [],
      chart_creation_favorite_selected: ''
    });
  }

  async chartCreationSave(event: React.FormEvent<HTMLFormElement>) {
    const target = event.target as any;
    const to_save: { [key: string]: any } = {};
    for (let i = 0; i < target.length; i++) {
      const element = target[i] as HTMLInputElement;
      // if((["submit", "reset"]).includes(element.type)) continue;
      if (element.name == "") continue;
      
      to_save[element.name] = element.value;
      // element.value = "";
    }

    to_save["variables"] = this.state.chart_creation_variables_container;
    to_save["charts"] = this.state.chart_creation_selected_datasets;
    to_save["x_lines"] = this.state.chart_creation_x_lines;
    to_save["favorite_name"] = this.state.chart_creation_favorite_selected;

    let equation = to_save["equation"] || "NO";
    for (let variable of this.state.chart_creation_variables_container) {
      equation = equation.split(variable.variable).join("1");
    }

    if (equation != "NO") {
      let result_eval;
      try {
        result_eval = eval(equation);
      } catch {
        toast.error("Equation is not valid");
        return;
      }

      if (equation != "NO" && isNaN(result_eval)) {
        toast.error("Equation is not valid");
        return;
      }
    }

    const res = await toast.promise(
      fetch(apiUrl + "chart", { method: this.state.selected_chart == "" ? "PUT" : "POST", body: JSON.stringify(to_save), headers: { Target: this.state.selected_chart } }),
      {
        loading: "Loading...",
        success: this.state.selected_chart == "" ? "Chart created" : "Chart edited",
        error: "Error!",
      }
    );

    const response = await res.json();
    console.log(response.uuid);
    this.selectChart(response.uuid);
    this.updateCharts();
  }

  selectChart(chart_uuid?: string) {
    this.setState({ selected_chart: chart_uuid || "" });
    if (chart_uuid) {
      toast.promise(
        new Promise<void>(async (resolve, reject) => {
          get(ref(db, `charts_datasets/${chart_uuid}`))
            .then((snap) => {
              const values = snap.val();
              console.log(values);
              this.setState({
                show_equation: values.chart_type == "equation",
                chart_creation_selected_datasets: values.charts,
                chart_creation_variables_container: values.variables,
                chart_creation_x_lines: values.x_lines || [],
                chart_creation_favorite_selected: values.favorite_name,
              });

              setTimeout(() => {
                Object.entries(values).forEach(([key, value]) => {
                  const element_path = `#chart-creation-form *[name="${key}"]`;
                  const element = document.querySelector(element_path) as HTMLInputElement;
                  if (element) element.value = value as string;
                });
                resolve();
              }, 500);
            })
            .catch(reject);
        }),
        {
          loading: "Loading...",
          success: "Data selected",
          error: "Error!",
        }
      );
    } else (document.querySelector('#chart-creation-form input[type="reset"]') as HTMLButtonElement)?.click();
  }

  deleteChart() {
    if (this.state.selected_chart == "") return;
    toast.promise(
      new Promise<void>(async (resolve, reject) => {
        await fetch(apiUrl + `chart`, { method: "DELETE", headers: { Target: this.state.selected_chart } });
        this.selectChart();
        resolve();
      }),
      {
        loading: "Loading...",
        success: "Chart deleted",
        error: "Error!",
      }
    );
  }

  updateSettings() {
    toast.promise(fetch(apiUrl + "update_dashboard_info"), {
      loading: "Loading...",
      success: "Settings updated!",
      error: "Error!",
    });
  }

  updateCharts() {
    toast.promise(fetch(apiUrl + "update_charts_th"), {
      loading: "Loading...",
      success: "Charts updated!",
      error: "Error!",
    });
  }

  updateSingleChart(chart_uuid: string) {
    toast.promise(fetch(apiUrl + "chart", { method: "GET", headers: { Target: chart_uuid } }), {
      loading: "Loading...",
      success: "Charts updated!",
      error: "Error!",
    });
  }

  addEmptyLineOnXAxis() {
    const x_axis = this.state.chart_creation_x_lines;
    x_axis.push({ name: "", value: 0 });
    this.setState({ chart_creation_x_lines: x_axis });
  }

  removeLineOnXAxis(index: number) {
    const x_axis = this.state.chart_creation_x_lines.filter((_, i) => i != index);
    this.setState({ chart_creation_x_lines: x_axis || [] });
  }

  changeLineOnXAxis(index: number, options: { name?: string; value?: number }) {
    let x_axis = this.state.chart_creation_x_lines;
    x_axis[index] = { ...x_axis[index], ...options };
    this.setState({ chart_creation_x_lines: x_axis });
  }

  setGlobalVariable(variable: string, value?: string) {
    set(ref(db, `global_variables/${variable}`), value || '');
  }

  updateDashboardPages() {
    // Create random number
    const random = Math.floor(Math.random() * 100);

    set(ref(db, 'update_dashboard_pages'), random);
    toast.success("Dashboard pages updated");
  }

  avoidSpecialCharacter(target: EventTarget) {
    const input = (target as HTMLInputElement);
    const AVOID_CHARACTER = ['/', '$', '#', '[', ']', '.']

    const value = Array.from(input.value).filter(char => !AVOID_CHARACTER.includes(char));
    input.value = value.join('');
  }

  render(): React.ReactNode {
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <Modal show={this.state.dialogs_select_dataset} centered onHide={() => this.setState({ dialogs_select_dataset: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Select dataset</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div>
              <label className="form-label text-black">Datasets</label>
              <select id="dataset-select" className="form-select">
                {(Object.entries(this.state.datasets || {}) || []).sort((a, b) => b[1].name > a[1].name ? -1 : 1).map(([uuid, info]) => {
                  return (
                    <option key={uuid} value={uuid}>
                      {info.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="d-flex justify-content-end align-items-center">
              <button onClick={() => this.setState({ dialogs_select_dataset: false })} className="btn btn-outline-secondary me-2">
                Close
              </button>
              <button
                onClick={() => {
                  this.setState({ dialogs_select_dataset: false });
                  this.selectDatas((document.getElementById("dataset-select") as HTMLInputElement).value);
                }}
                className="btn btn-primary"
              >
                Select
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.dialogs_selected_chart} centered onHide={() => this.setState({ dialogs_selected_chart: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Select chart</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div>
              <label className="form-label text-black">Charts</label>
              <select id="chart-select" className="form-select">
                {Object.entries(this.state.charts || {}).sort((a, b) => b[1].name > a[1].name ? -1 : 1).map(([uuid, info]) => (
                  <option value={uuid} key={uuid}>
                    {info.name}
                  </option>
                ))}
              </select>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="d-flex justify-content-end align-items-center">
              <button onClick={() => this.setState({ dialogs_selected_chart: false })} className="btn btn-outline-secondary me-2">
                Close
              </button>
              <button
                onClick={() => {
                  this.setState({ dialogs_selected_chart: false });
                  this.selectChart((document.getElementById("chart-select") as HTMLInputElement).value);
                }}
                className="btn btn-primary"
              >
                Select
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        {this.state.user ? (
          <div className="w-100 h-100 d-flex flex-column align-items-center">
            <div className="w-100 bg-white shadow d-flex justify-content-between align-items-center px-2 py-1 mb-3">
              <img src={this.state.user.photoURL || ""} width="50" className="rounded-circle" alt="" />
              <span className="fw-bold text-primary h3 m-0">{this.state.user.displayName}</span>
              <button className="btn btn-outline-danger">Logout</button>
            </div>
            <Swiper slidesPerView={1} threshold={10} keyboard={true} onSwiper={e => this.swiper = e}>
              <SwiperSlide>
                <form
                  id="data-creation-form"
                  className="w-100 text-center px-2"
                  style={{ maxWidth: "30em" }}
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.createData(e);
                  }}
                >
                  <div className="title-container">
                    <h1 className="m-0">Data creation</h1>
                    <span className="title-icon right">
                      {this.state.selected_dataset == "" ? (
                        Object.keys(this.state.datasets || []).length != 0 ? (
                          <Edit onClick={() => this.setState({ dialogs_select_dataset: true })}></Edit>
                        ) : (
                          <span></span>
                        )
                      ) : (
                        <Close onClick={() => this.selectDatas()}></Close>
                      )}
                    </span>
                    {this.state.selected_dataset != "" && <Delete onClick={() => this.deleteDataset(this.state.selected_dataset)} className="title-icon left text-danger"></Delete>}
                  </div>
                  <div className="mb-2 text-start">
                    <label className="form-label">Name</label>
                    <input className="form-control" name="name" type="text" onChange={e => this.avoidSpecialCharacter(e.target!)} placeholder="Data name" required />
                  </div>
                  {Object.values(this.state.selects).map((select_setting) => {
                    const options_list = select_setting.values.map((v) => {
                      let display: string;
                      let value: string;
                      if (select_setting.name == "owners") {
                        display = (v as any).email;
                        value = (v as any).person_id;
                      } else if (select_setting.name == "chart_type") {
                        display = (v as any).display;
                        value = (v as any).name;
                      } else {
                        display = v;
                        value = v;
                      }

                      return (
                        <option value={value} key={value}>
                          {display}
                        </option>
                      );
                    });
                    // console.log(options_list);
                    return (
                      <div className="mb-2 text-start" key={select_setting.db_path}>
                        <label className="form-label">{select_setting.display}</label>
                        <select className="form-select" name={select_setting.name} multiple={select_setting.name == 'channels'}>
                          {select_setting.show_all && <option value="">All</option>}
                          {options_list}
                        </select>
                      </div>
                    );
                  })}
                  <div className="mb-2 text-start">
                    <div className="row">
                      <div className="col-6">
                        <label className="form-label">Pipelines</label>
                        <select className="form-select" required name="pipeline" onChange={(e) => this.setState({ selected_pipeline: +e.target.value })}>
                          {this.state.pipelines.map((pipeline) => (
                            <option key={pipeline.id} value={pipeline.id}>
                              {pipeline.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {this.state.selected_pipeline != -1 && (
                        <div className="col-6">
                          <label className="form-label">Stages</label>
                          <select className="form-select" required name="stage">
                            {this.getPipeline(this.state.selected_pipeline)!.stages.map((stage) => (
                              <option key={stage.id} value={stage.id}>
                                {stage.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="w-100 d-flex justify-content-around mt-4 mb-2">
                    <input type="submit" className="btn btn-primary" value={this.state.selected_dataset == "" ? "Save" : "Edit"} />
                    <input type="reset" className="btn btn-outline-secondary" value="Clear" />
                  </div>
                </form>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                <div className="title-container">
                  <h1 className="m-0">Chart creation</h1>
                  {this.state.selected_chart != "" && (
                    <span className="title-icon left" onClick={() => this.deleteChart()}>
                      <Delete className="text-danger"></Delete>
                    </span>
                  )}
                  <span className="title-icon right">
                    {this.state.selected_chart == "" ? (
                      Object.keys(this.state.datasets || []).length != 0 ? (
                        <Edit onClick={() => this.setState({ dialogs_selected_chart: true })}></Edit>
                      ) : (
                        <span></span>
                      )
                    ) : (
                      <Close onClick={() => this.selectChart()}></Close>
                    )}
                  </span>
                </div>
                <form
                  id="chart-creation-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.chartCreationSave(e);
                  }}
                  className="px-2"
                >
                  <div className="mb-3">
                    <label className="form-label">Name</label>
                    <input type="text" name="name" required onChange={e => this.avoidSpecialCharacter(e.target!)} className="form-control" placeholder="Chart name" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label w-100">Page name</label>
                    
                    <Autocomplete
                      wrapperStyle={{maxHeight: '100%'}}
                      items={this.state.favorite_pages}
                      value={this.state.chart_creation_favorite_selected}
                      onChange={(e) => this.setState({ chart_creation_favorite_selected: e.target.value })}
                      onSelect={(e) => this.setState({ chart_creation_favorite_selected: e })}
                      getItemValue={(item) => item}
                      renderInput={(props) => <input {...props} type="text" name="favorite_name" className="form-control w-100" placeholder="Page name" />}
                      renderItem={(item, isHighlighted) => (
                        <div
                          className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                          key={item}
                        >{item}</div>
                      )}
                    />
                  </div>
                  <div className="mb-2 text-start">
                    <div className="row">
                      <div className="col-6">
                        <label className="form-label">Start date</label>
                        <input className="form-control" name="start_date" type="date" required />
                      </div>
                      <div className="col-6">
                        <div className="d-flex align-items-center form-label">
                          <label>End date</label>
                          <input type="checkbox" className="ms-2" checked={this.state.chart_end_date} onChange={(e) => this.setState({ chart_end_date: e.target.checked })} />
                        </div>
                        {this.state.chart_end_date && <input className="form-control" name="end_date" type="date" required />}
                        {!this.state.chart_end_date && <input className="form-control" readOnly disabled type="date" value={new Date().toISOString().split("T")[0]} />}
                      </div>
                    </div>
                  </div>
                  <div className="mb-2 text-start">
                    <label className="form-label">Group By</label>
                    <select className="form-select" name="group_by">
                      <option value="Day">Day</option>
                      <option value="Week">Week</option>
                      <option value="Month">Month</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Datasets</label>
                    <Multiselect
                      options={this.MultiSelect__options()}
                      selectedValues={this.state.chart_creation_selected_datasets}
                      onSelect={(e) => this.MultiSelect__edit_selected(e)}
                      onRemove={(e) => this.MultiSelect__edit_selected(e)}
                      id="id"
                      displayValue="name"
                      className="form-select"
                    ></Multiselect>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Chart Type</label>
                    <select name="display_type" className="form-select" required>
                      {AVAILABLE_CHARTS.map((chart) => (
                        <option value={chart} key={chart}>
                          {chart}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Type</label>
                    <select className="form-select" name="chart_type" required onChange={(e) => this.setState({ show_equation: e.target.value == "equation" })}>
                      <option value="overlap">{this.state.chart_creation_selected_datasets.length <= 1 ? "Single" : "Overlap"}</option>
                      <option value="equation">Equation</option>
                    </select>
                  </div>
                  {this.state.show_equation && (
                    <div className="mb-3">
                      <label className="form-label">Equation</label>
                      <textarea name="equation" rows={4} required className="form-control"></textarea>
                      <table className="mt-1 w-100">
                        <thead>
                          <tr>
                            <td>Dataset</td>
                            <td>Variable</td>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.chart_creation_variables_container.map((v) => (
                            <tr key={v.dataset_id}>
                              <td>{v.dataset_name}</td>
                              <td className="btn-link" onClick={() => this.addVariable(v.dataset_name)}>
                                {this.chartCreationGetVariableName(v.dataset_name)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  <div className="mb-3">
                    <label className="form-label">Y-axis</label>
                    <div className="row">
                      <div className="col-6">
                        <span>Name</span>
                      </div>
                      <div className="col-6">
                        <span>Value</span>
                      </div>
                    </div>
                    {(this.state.chart_creation_x_lines || []).map((x_line, index) => (
                      <div className="row mb-2" key={x_line.name + index.toString()}>
                        <div className="col-6">
                          <input
                            onChange={(e) => this.changeLineOnXAxis(index, { name: e.target.value })}
                            value={x_line.name}
                            className="form-control"
                            type="text"
                            placeholder="Value's name"
                            required
                          />
                        </div>
                        <div className="col-6">
                          <div className="d-flex align-items-center justify-content-between">
                            <input
                              onChange={(e) => this.changeLineOnXAxis(index, { value: +e.target.value })}
                              value={x_line.value}
                              className="form-control"
                              type="number"
                              step="0.1"
                              placeholder="Value"
                              required
                            />
                            <Delete className="text-danger" onClick={() => this.removeLineOnXAxis(index)}></Delete>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="my-2 w-100">
                      <span className="w-100 btn btn-outline-primary" onClick={() => this.addEmptyLineOnXAxis()}>
                        Add Line on Y Axis
                      </span>
                    </div>
                  </div>
                  <div className="d-flex w-100 justify-content-around align-items-center mb-3">
                    <input type="submit" className="btn btn-primary" value={this.state.selected_chart == "" ? "Save" : "Edit"} />
                    <input type="reset" className="btn btn-outline-secondary" onClick={() => this._chartCreationClear()} value="Clear" />
                  </div>
                </form>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                <div className="title-container">
                  <h1 className="m-0">Settings</h1>
                </div>
                <div className="px-2 d-flex flex-column align-items-center">
                  <button className="btn btn-primary mb-3 w-100" onClick={() => this.updateSettings()}>
                    Update settings
                  </button>
                  <hr className="w-100" />
                  <div className="mb-3 text-start w-100">
                    <label className="form-label">Global group by</label>
                    <select value={this.state.global_group_by} onChange={e => {this.setState({global_group_by: e.target.value}); this.setGlobalVariable('group_by', e.target.value)}} className="form-select">
                      <option value="">Default</option>
                      <option value="Day">Day</option>
                      <option value="Week">Week</option>
                      <option value="Month">Month</option>
                    </select>
                  </div>
                  <div className="mb-3 text-start w-100">
                    <div className="row">
                      <div className="col-6">
                        <label className="form-label">Global start date</label>
                        <input value={this.state.global_start_date} onChange={e => {this.setState({global_start_date: e.target.value}); this.setGlobalVariable('start_date', e.target.value)}} className="form-control" name="start_date" type="date" />
                      </div>
                      <div className="col-6">
                        <label className="form-label">Global end date</label>
                        <input value={this.state.global_end_date} onChange={e => {this.setState({global_end_date: e.target.value}); this.setGlobalVariable('end_date', e.target.value)}} className="form-control" name="end_date" type="date" />
                      </div>
                    </div>
                  </div>
                    <button className="btn btn-primary w-100 mb-3" onClick={() => this.updateCharts()}>
                      Update charts
                    </button>
                  <hr className="w-100" />

                    <button className="btn btn-primary w-100" onClick={() => this.updateDashboardPages()}>
                      Reload dashboard
                    </button>
                </div>
                </div>
              </SwiperSlide>
            </Swiper>
            <div className="arrows-container mt-5">
                <ChevronLeft className="arrow left" onClick={() => this.swipeLeft()}></ChevronLeft>
                <ChevronRight className='arrow right' onClick={() => this.swipeRight()}></ChevronRight>
            </div>
          </div>
        ) : (
          <button className="btn btn-primary" onClick={() => this.googleLogin()}>
            Sign in with Google
          </button>
        )}
      </div>
    );
  }
}

export default CreateData;
