
export const firebaseConfig = {
    apiKey: "AIzaSyBT8q6AoYFon8SzfO-J-j6wtTqMbPff1no",
    authDomain: "futura-dashboard-2ac42.firebaseapp.com",
    projectId: "futura-dashboard-2ac42",
    storageBucket: "futura-dashboard-2ac42.appspot.com",
    messagingSenderId: "931847832362",
    appId: "1:931847832362:web:5858d850c24445cdb09453",
    measurementId: "G-CLDTGYRTBB",
    databaseUrl: "https://futura-dashboard-2ac42-default-rtdb.europe-west1.firebasedatabase.app"
};

export const apiUrl = 'https://futura-dashboard-be.herokuapp.com/';
// export const apiUrl = "http://localhost:4501/";